import { Box, Typography } from '@mui/material';
import TypographyLink from '../../components/navigation/typography-link';
import React from 'react';
import { Trans } from 'react-i18next';
import RestrictedByRole from '../../components/restricted-by-role';

const ContentCreation = (props: JSX.IntrinsicAttributes) => (
    <Box sx={{ padding: 5 }}>
        <RestrictedByRole role="ADMIN">
            <Typography variant="h1">Create content</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <TypographyLink sx={{ padding: 5 }} to="/content-creation/personalised-content">
                    <Trans i18nKey="personalised-content" />
                </TypographyLink>
                <TypographyLink sx={{ padding: 5 }} to="/content-creation/assignments">
                    <Trans i18nKey="assignments" />
                </TypographyLink>
            </Box>
        </RestrictedByRole>
    </Box>
);

export default ContentCreation;
